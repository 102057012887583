import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import { formatExample } from '../../../helpers/formatExample'
import useActions from '../../../hooks/useActions'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import { createFoodAction, getFoodDataAction, updateFoodAction } from '../../../redux/actions/foodActions'
import SelectFile from '../../Common/SelectFile'
import CustomButton from '../../Custom/CustomButton'
import CustomInput from '../../Custom/CustomInput'
import CustomLabel from '../../Custom/CustomLabel'
import CustomOption from '../../Custom/CustomOption'
import SelectLanguage from '../../Common/SelectLanguage'
import CustomTextArea from '../../Custom/CustomTextArea'
import Select from 'react-select'
import formatFormData from '../../../helpers/formatFormData'

const SizesForm = ({type, initialValues, language, setLanguage}) => {

    const {register, handleSubmit, reset } = useForm();
    
    const [icon, setIcon] = useState({
        url: '',
        name: '',
        file: null
    })

    const [selectedFoodTypes, setSelectedFoodTypes] = useState([]);

    const dispatch = useDispatch();

    const history = useHistory();

    const {isLoading, onLoading, onFinally, onError} = useActions();

    const { foodTypes: {list: foodTypes} } = useSelector(state => state.food);
    
    const submitForm = (data) => {
        onLoading();
        const formData = formatFormData({
            ...data,
            type_id: selectedFoodTypes,
            icon: icon.file
        })
        if (type === 'create') {
            dispatch(createFoodAction({
                field: 'sizes',
                data: formData,
                language
            }, {
            onSuccess() {
                onFinally();
                history.push(getPathname('food/sizes'))
            },
            onError
            }))
        } else {
            dispatch(updateFoodAction({
                field: 'sizes',
                id: initialValues.id,
                data: formData,
                language
            }, {
                onSuccess() {
                    onFinally();
                    setIcon({
                        url: icon.url,
                        name: icon.name,
                        file: null
                    })
                },
                onError
            }))
        }
    
    }

    useEffect(() => {
        dispatch(getFoodDataAction({
            field: 'foodTypes'
        }))
    }, [])

    useEffect(() => {
        if (initialValues) {
            
            reset((() => {
                const { file, icon, types, ...rest } = initialValues;
                return rest
            })())

            if (initialValues?.icon) {
                setIcon({
                    id: initialValues.file?.id,
                    url: initialValues.icon,
                    file: null,
                    name: initialValues.file?.name
                })
            }

            if (initialValues.types?.length) {
                setSelectedFoodTypes(initialValues.types.map(type => type.id));
            }
        }
    }, [initialValues])

  return (

    <form onSubmit={handleSubmit(submitForm)}>
        <Row>

            <Col lg={3}>
                <Card className='p-3'>
                    <CustomLabel>
                        Icon
                    </CustomLabel>
                    <SelectFile
                        type='image'
                        max={1}
                        display
                        selectedFiles={icon}
                        setSelectedFiles={setIcon}
                        initialValues={initialValues}
                    />
                </Card>
            </Col>
            
            <Col lg={9}>
                <Card className='p-3'>
                    {type === 'update' && 
                        <div className='d-flex justify-content-end'>
                            <SelectLanguage
                                language={language}
                                onChange={lang => setLanguage(lang)}
                            /> 
                        </div>
                    }
                    <Row>
                        <Col md={6}>
                            <CustomLabel>
                                Type
                            </CustomLabel>
                            <Select 
                                options={foodTypes.map(type => ({
                                    label: type.name,
                                    value: type.id
                                }))}
                                value={foodTypes.filter(type => selectedFoodTypes.includes(type.id)).map(type => ({
                                    label: type.name,
                                    value: type.id
                                }))}
                                onChange={(values) => setSelectedFoodTypes(values.map(value => value.value))}
                                isMulti
                            />
                        </Col>
                    </Row>

                    <Row className='gy-4 mt-1'>

                        <Col md={6}>
                            <CustomLabel translatable>
                                Name
                            </CustomLabel>
                            <CustomInput 
                                type="text"
                                {...register('name')}
                                placeholder={formatExample("XXL")}
                            />
                        </Col>

                        <Col md={6}>
                            <CustomLabel>
                                Pieces
                            </CustomLabel>
                            <CustomInput 
                                type="number"
                                {...register('pieces')}
                                placeholder={formatExample("44")}
                            />
                        </Col>

                        <Col md={6}>
                            <CustomLabel>
                                Weight
                            </CustomLabel>
                            <CustomInput 
                                type="number"
                                {...register('weight')}
                                placeholder={formatExample("144.84")}
                            />
                        </Col>

                        <Col md={6}>
                            <CustomLabel>
                                Unit
                            </CustomLabel>
                            <select 
                                {...register('unit')}
                                className="form-select"
                            >
                                <CustomOption value="vm">cm</CustomOption>
                                <CustomOption value="in">in</CustomOption>
                                <CustomOption value="g">g</CustomOption>
                            </select>
                        </Col>

                        
                        <Col md={6}>
                            <CustomLabel>
                                Code
                            </CustomLabel>
                            <CustomInput 
                                type="text"
                                {...register('code')}
                                placeholder={formatExample("144.84")}
                            />
                        </Col>

                        <Col md={6}>
                            <CustomLabel>
                                Class
                            </CustomLabel>
                            <CustomInput 
                                type="text"
                                {...register('class')}
                                placeholder={formatExample("Class")}
                            />
                        </Col>

                        <Col md={6}>
                            <CustomLabel>
                                Note
                            </CustomLabel>
                            <CustomTextArea 
                                {...register('note')}
                                placeholder={formatExample("Note")}
                            />
                        </Col>


                    </Row>

                    <Row className='mt-3'>
                        <Col>
                            <CustomButton
                                className="btn-success"
                                isLoading={isLoading}
                            >
                                {type === 'create' ? 'Create' : 'Update'}
                            </CustomButton>
                        </Col>
                    </Row>
                    
                </Card>
            </Col>
        </Row>
    </form>
  )
}

export default SizesForm